
<template>
        <div>
            <div class="error-page">
                <h2 class="headline text-yellow"> 404</h2>

                <div class="error-content">
                <h3><i class="fa fa-warning text-yellow"></i> Stránka nenalezena.</h3>

                <p>
                    Nenašli jsme stránku, kterou hledáte.
                    Mezitím se můžete <a href="/#/">vrátit na hlavní stránku</a>.
                </p>

                <!-- <form class="search-form">
                    <div class="input-group">
                    <input type="text" name="search" class="form-control" placeholder="Search">

                    <div class="input-group-btn">
                        <button type="submit" name="submit" class="btn btn-warning btn-flat"><i class="fa fa-search"></i>
                        </button>
                    </div>
                    </div>
                </form> -->
                </div>
                <!-- /.error-content -->
            </div>
            
        </div>
</template>

<script>
const name = 'NotFound';

import { mapGetters, mapActions } from "vuex";

export default {
    name,
    data() {
        return {
            hover: []
        };
    },
    computed: {
        ...mapGetters(["getAllPages", 'user'])
    }, 
    methods: {
        ...mapActions(["makeActiveTab", "addTab"]),
    },
    created(){
        const page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(page);
        this.makeActiveTab(page);
    }
}
</script>

<style scoped>
a {
    cursor: pointer;
}
</style>